import * as types from "../types/snackbar_type";

const initialState = {
  //For snackbar
  showSnackBar: false,
  snackBarMessage: "",
  key: "1",
  severity: "success",
  isMoreThan2MB: false,
  accountCreated: false,
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    //For snackbar
    case types.SHOW_SNACKBAR: {
      return {
        ...state,
        showSnackBar: true,
        key: action.key,
        snackBarMessage: action.snackBarMessage,
        isMoreThan2MB: action.isMoreThan2MB,
        accountCreated: action.accountCreated,
        severity: action.severity,
        showActionButton: action.showActionButton,
      };
    }
    case types.HIDE_SNACKBAR: {
      return {
        ...state,
        showSnackBar: false,
        snackBarMessage: "",
        key: "1",
        showActionButton: false,
      };
    }
    default: {
      return state;
    }
  }
};
