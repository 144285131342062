/*
 * Author: Kyle Hin
 * This is a customized Axios that allow http request cancellation and retry on failure
 * 1. In this customized axios, API call can be cancelled using cancel token after a certain amount of time (seconds)
 * 2. API call can be cancelled when component is unmounted as well to prevent unnecessary API call when user go in and out a specific screen
 * 3. canCancel (true/false) value could be provided to control whether this request need to be cancelled with 1.
 * 4. retryOption can be provided for retry behaviour
 * 	  a. retryOption = {retry_time, retry_status_code} //more condition can be added as one see fit
 * 5. TODO next - different handling for post request regarding cancel token behaviour -> post request will not be automatically cancelled when upload progress == 100%
 */
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment/moment";
import * as config from "../config/config";
import { getItem } from "../helpers/data_management";
import { store } from "../redux/stores/store";
import * as types from "../redux/types/user_type";

const domain = config.baseURL;
const timeout = 20000; // 20 seconds of connection timeout

// For Strapi CMS
const strapiDomain = config.strapiBaseURL;
const strapiHeaders = {
  Authorization: `Bearer ${process.env.STRAPI_AUTH_TOKEN}`,
  Accept: "application/json",
  "Content-Type": "application/json",
};

// Create a specific axios instance for Strapi calls
const strapiAxios = axios.create({
  baseURL: strapiDomain,
  headers: strapiHeaders,
});

// Remove traceparent header for Safari compatibility
strapiAxios.interceptors.request.use((config) => {
  if (config.headers["traceparent"]) {
    delete config.headers["traceparent"];
  }
  return config;
});

/**
 *  Due to NodeJS V17 and above,
    SSR can't access http://localhost
    Need to use http://127.0.0.1 instead
    Refer to: https://forum.strapi.io/t/strapi-axios-request-on-ssr-fails-with-connect-econnrefused-but-csr-works-well/20799
 * @param {boolean} urlToResolveConnectionError
 */
export async function strapiCall(graphqlQuery) {
  try {
    // Use the dedicated Strapi axios instance
    const response = await strapiAxios.post("", graphqlQuery);
    return response;
  } catch (error) {
    // Properly handle and propagate the error with details
    console.error("Strapi API Error:", {
      status: error.response?.status,
      message: error.message,
      details: error.response?.data,
    });

    return {
      status: error.response?.status || 500,
      data: error.response?.data || {},
      error: {
        message: error.message,
        details: error.response?.data,
      },
    };
  }
}

const apiCall = async (
  url,
  params = {},
  payload = "",
  usetoken = true,
  advice_page
) => {
  return new Promise((resolve, reject) => {
    const currentUrl = url;
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    store.getState().axios.cancelTokens[params.cancelTokenKey] = source; // Assign cancel token source to axios redux state

    url = advice_page
      ? process?.env?.NEXT_PUBLIC_APP_WP_BLOG + url
      : domain + url; // Full url with domain + sub url

    if (params.useFullUrl) {
      url = currentUrl;
    }

    // Setting Timeout to cancel axios call after predefined seconds
    let mTimeout;
    if (params.canCancel) {
      mTimeout = setTimeout(() => {
        cancelAxiosRequest(params.cancelTokenKey, "connection timeout");
      }, timeout);
    }

    if (usetoken) {
      // Need include authorization token
      setAuthorizationToken(
        getItem(types.USER_TOKEN)
          ? getItem(types.USER_TOKEN)
          : Cookies.get(process.env.NEXT_PUBLIC_JWT_COOKIE)
          ? Cookies.get(process.env.NEXT_PUBLIC_JWT_COOKIE)
          : "", // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoiMDVmYWJhYjEtMDVlZC00ODc4LWFhNDctMmNmOTFiMjgyZWVkIn0.gcsftbLe4RwIJltNbUT9krLNaDUd3BTxs6acskRFE7Y"
        params.useFullUrl
      );
    }

    if (!params.retryOption) {
      params["retryOption"] = { retry_time: 0 }; // default = no retry
    }

    postRequest(
      url,
      payload,
      store.getState().axios.cancelTokens[params.cancelTokenKey],
      params.cancelTokenKey
    ).then((response) => {
      clearingTimeOut(mTimeout);
      resolve(response);
    });
  });
};

const postRequest = (url, payload, cancelToken, cancelTokenKey) => {
  return new Promise((resolve, reject) => {
    axios
      .post(url, payload, {
        cancelToken: cancelToken.token,
        onUploadProgress: (progressEvent) => {
          let percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
        onDownloadProgress: (progressEvent) => {
          let percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      })
      .then((response) => {
        // Remove cancel token upon success
        if (store.getState().axios.cancelTokens[cancelTokenKey]) {
          delete store.getState().axios.cancelTokens[cancelTokenKey];
        }

        resolve(response);
      })
      .catch((thrown) => {
        // Remove cancel token upon failure
        if (store.getState().axios.cancelTokens[cancelTokenKey]) {
          delete store.getState().axios.cancelTokens[cancelTokenKey];
        }

        if (axios.isCancel(thrown)) {
          resolve({ status: 400, success: false, isCancelled: true });
        } else {
          resolve({
            status: 400,
            isCancelled: false,
            error: thrown,
          });
        }
      });
  });
};

const cancelAxiosRequest = (cancelTokenKey, from) => {
  if (store.getState().axios.cancelTokens[cancelTokenKey]) {
    try {
      store.getState().axios.cancelTokens[cancelTokenKey].cancel(from);
      delete store.getState().axios.cancelTokens[cancelTokenKey];
    } catch (error) {
      // Failed to trigger cancel token
    }
  }
};

const setAuthorizationToken = (token, useBearer) => {
  axios.defaults.headers.common["Authorization"] = `${
    useBearer ? "Bearer " : ""
  }${token}`;
};

const clearingTimeOut = (timer) => {
  if (timer) {
    clearTimeout(timer);
  }
};

const obtainLightCastToken = () => {
  const client = axios.create({
    baseURL: "https://auth.emsicloud.com",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

  const path = "/connect/token";

  const postData = {
    client_id: process.env.LIGHTCAST_CLIENT_ID,
    client_secret: process.env.LIGHTCAST_CLIENT_SECRET,
    grant_type: "client_credentials",
    scope: "emsi_open",
  };

  const params = new URLSearchParams();
  for (var item in postData) {
    params.append(item, postData[item]);
  }

  client
    .post(path, params)
    .then((response) => {
      // token is in -> response.data.access_token
      if (
        response.data.accessToken !== null ||
        response.data.access_token != undefined
      ) {
        localStorage.setItem("lightcast-token", response.data.access_token);
      }
      if (
        response.data.expires_in !== null ||
        response.data.expires_in !== undefined
      ) {
        const timeOutDuration = moment().add(
          parseInt(response.data.expires_in) - 60,
          "seconds"
        );
        localStorage.setItem("lightcast-token-timeout", timeOutDuration);
      }
    })
    .catch((error) => {
      console.error("api_error:", error);
    });
};

export default {
  apiCall,
  cancelAxiosRequest,
  obtainLightCastToken,
};
