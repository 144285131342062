const sendTrackingEvent = (params) => {
  if (typeof dataLayer === "undefined") return;
  try {
    const dataLayerParams =
      process.env.NEXT_PUBLIC_JSW_ENV != "prod"
        ? { environment: "staging", ...params }
        : { ...params };

    dataLayer?.push(dataLayerParams);
  } catch (error) {
    // tracking error
  }
};

const signupTracking = (url, origin, job, company) => {
  let signupMedium = "internal";
  let signupSource = "";

  if (url.includes("/apply")) {
    if (origin === "search") {
      signupSource = job.category
        ? `jlp_easy_apply_${job.category}`
        : signupSource;
    } else if (origin === "individual_job_page") {
      signupSource = job.category
        ? `jdp_easy_apply_${job.category}`
        : signupSource;
    } else if (origin === "jdp_view_similar_jobs") {
      signupSource = "jdp_view_similar_jobs";
    } else if (origin === "jlp_view_similar_jobs") {
      signupSource = "jlp_view_similar_jobs";
    } else {
      signupSource = "";
    }
  } else if (url.includes("/companies")) {
    signupSource = "clp_navbar";

    if (Object.keys(company).length > 0) {
      signupSource = company.category
        ? `cdp_navbar_${company.category}`
        : signupSource;
    }
  } else if (url.includes("/jobs")) {
    if (url === "/jobs" || url.includes("/jobs?")) {
      signupSource = "jlp_navbar";

      if (origin === "jobAlert") {
        signupSource = "jlp_job_alert";
      }
    } else if (origin === "jobAlert") {
      signupSource = "jdp_job_alert";
    } else if (Object.keys(job).length > 0) {
      signupSource = job.category ? `jdp_navbar_${job.category}` : signupSource;
    }
  } else if (url.includes("/")) {
    signupSource = "hp_navbar";
  }

  if (origin === "job_recommendation") {
    signupSource = "jlp_fyp";
  }

  if (url === "/advice" || url.includes("/advice?page=")) {
    signupSource = "cap_landing_navbar";
  } else if (url.includes("/advice")) {
    signupSource = "cap_article_navbar";
  }

  return { signupMedium, signupSource };
};

export { sendTrackingEvent, signupTracking };
